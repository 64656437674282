export enum ReasonsForCancel {
  UNABLE_TO_OBTAIN_FINANCE = "Unable to obtain finance",
  CHANGE_OF_MIND = "Change of mind",
  MOVED_TO_DIFFERENT_LOT = "Moved to different lot",
  CUSTOMER_STOPPED_RESPONDING = "Customer stopped responding",
  OTHER = "Other",
}

export enum ReasonsForDecline {
  THIS_LOT_HAS_BEEN_ALLOCATED_TO_ANOTHER_PURCHASER = "This lot has been allocated to another purchaser",
  THIS_LOT_IS_NOT_SUITABLE_FOR_INVESTORS = "This lot is not suitable for investors",
  THIS_LOT_IS_NOT_SUITABLE_FOR_OWNER_OCCUPIERS = "This lot is not suitable for owner-occupiers",
  FINANCE_PRE_APPROVAL_IS_REQUIRED_TO_RESERVE_THIS_LOT = "Finance pre-approval is required to reserve this lot",
  OTHER = "Other",
}

export enum ReasonsForExpire {
  UNABLE_TO_OBTAIN_FINANCE = "Unable to obtain finance",
  CHANGE_OF_MIND = "Change of mind",
  MOVED_TO_DIFFERENT_LOT = "Moved to different lot",
  CUSTOMER_STOPPED_RESPONDING = "Customer stopped responding",
  OTHER = "Other",
}
